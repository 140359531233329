import { Text } from "@pancakeswap/uikit";
import { Button } from "antd";
import styled from "styled-components";

export const ContainerMask = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    z-index: 0;
    left: 0;
    bottom: 110px;
    top: 0;
    background: url("/images/tonusd/bg.jpg");
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    @media screen and (max-width:768px) {
        display: none;
    }
`

export const ContainerAirdrop = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 90vh;
    z-index: 10;
    padding-left: 1rem;
    @media screen and (max-width:768px) {
        padding-left: 0rem;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-top: 32px;
        min-height: calc(100vh - 100px);
    }
    @media screen and (max-width:576px) {
        margin-bottom: 2rem;
    }
`

export const ContainerContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
`

export const ContainerImage = styled.div`
    width: 100%;
    height: 332px;
    max-width: 1000px;
`
export const ContainerFormSubmit = styled.div`
    width: 550px;
    height: 100%;
    min-height: 100px;
    @media screen and (max-width: 768px) {
        width: 550px;
        padding: 2rem 2rem;
        box-shadow: 0px 0px 1.5px 0px #FFFFFF4D inset;
        background: #FFFFFF0D;
        border-radius: 16px;
    }
    @media screen and (max-width: 576px) {
        width: 100%;
        padding: 2rem 1rem;
    } 
`
export const CsTextHeading = styled(Text)`
    color: white;
    font-size: 29px;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    @media screen and (max-width:576px) {
        font-size: 22px;
    }
`

export const CsButtonSubmit = styled(Button)`
    width: 100%;
    background: #EDA426;
    color: white;
    box-shadow: 0px 3.22px 3.22px 0px #00000040;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    height: 40px;
    &:hover {
        color: white !important;
    }
`

export const CsTitle = styled(Text)`
    color: #EDA426;
    font-weight: 700;
    font-size: 62px;
    @media screen and (max-width: 576px) {
        font-size: 42px;
    }
`