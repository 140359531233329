import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" {...props}>
        <g clip-path="url(#clip0_182461_28474)">
          <rect width="20" height="20" fill="white" fill-opacity="0.01" />
          <path
            d="M17.1436 1.07143H14.7328V0.178571C14.7328 0.0803571 14.6525 0 14.5543 0H13.3043C13.2061 0 13.1257 0.0803571 13.1257 0.178571V1.07143H9.73284V0.178571C9.73284 0.0803571 9.65248 0 9.55427 0H8.30427C8.20605 0 8.1257 0.0803571 8.1257 0.178571V1.07143H5.71498C5.31989 1.07143 5.0007 1.39062 5.0007 1.78571V4.46429H2.85784C2.46275 4.46429 2.14355 4.78348 2.14355 5.17857V19.2857C2.14355 19.6808 2.46275 20 2.85784 20H14.2864C14.6815 20 15.0007 19.6808 15.0007 19.2857V17.1429H17.1436C17.5386 17.1429 17.8578 16.8237 17.8578 16.4286V1.78571C17.8578 1.39062 17.5386 1.07143 17.1436 1.07143ZM13.3936 18.3929H3.7507V6.07143H8.61677V9.95536C8.61677 10.4487 9.01632 10.8482 9.50963 10.8482H13.3936V18.3929ZM13.3936 9.41964H10.0453V6.07143H10.0498L13.3936 9.41518V9.41964ZM16.2507 15.5357H15.0007V8.75L10.715 4.46429H6.60784V2.67857H8.1257V3.39286C8.1257 3.49107 8.20605 3.57143 8.30427 3.57143H9.55427C9.65248 3.57143 9.73284 3.49107 9.73284 3.39286V2.67857H13.1257V3.39286C13.1257 3.49107 13.2061 3.57143 13.3043 3.57143H14.5543C14.6525 3.57143 14.7328 3.49107 14.7328 3.39286V2.67857H16.2507V15.5357Z"
            fill="#EDA426"
          />
        </g>
        <defs>
          <clipPath id="clip0_182461_28474">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
