//node_modules
import { PasteIcon } from "@pancakeswap/uikit";
import { Button, Col, Flex, Form, Input, Row, Typography, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "state";
//components
import PageFullWidth from "components/Layout/PageFullWidth";
//actions
import {
  fetchIsWhitelist,
  fetchTonWalletByBscWallet,
} from "state/airdrop/action";
//selector
import { useAirdropSelector } from "state/airdrop/selector";
//function
import useActiveWeb3React from "hooks/useActiveWeb3React";
//constants
//styled
import { airdropApi } from "config/api/airdrop";
import {
  ContainerAirdrop,
  ContainerFormSubmit,
  ContainerMask,
  CsTextHeading,
  CsTitle,
} from "./styled";

const { Text } = Typography;

const ComingSoonScreen = () => {
  // -------------------------- VAR ---------------------------
  const [form] = Form.useForm();
  const { account } = useActiveWeb3React();
  // -------------------------- STATE -------------------------
  const [isLoading, setLoading] = useState(false);
  // -------------------------- REDUX -------------------------
  const dispath = useAppDispatch();
  const airdrop = useAirdropSelector();
  const { isWhitelist, tonWallet } = airdrop;
  // -------------------------- FUNCTION ----------------------
  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (res) => {
        try {
          const response = await airdropApi.postAddWallet({
            bscWallet: account,
            tonWallet: res?.accountTon,
          });
          console.log("response", response);
          if (response?.status === 200) {
            message.success({
              content: "Successfully submitted the TON wallet address",
              duration: 1,
            });
            dispath(fetchTonWalletByBscWallet({ bscWallet: account }));
            dispath(fetchIsWhitelist({ bscWallet: account }));
          }
        } catch (error) {
          console.log("Submit failed:", error);
          message.error({
            content: "Something went wrong. Please try to again!",
            duration: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handlePaste = () => {
    if (!Boolean(tonWallet)) {
      navigator.clipboard.readText().then((clipText) => {
        form.setFieldValue("accountTon", clipText);
      });
    }
  };
  // -------------------------- EFFECT ------------------------

  useEffect(() => {
    if (Boolean(tonWallet)) {
      form.setFieldValue("accountTon", tonWallet);
    } else {
      form.setFieldValue("accountTon", "");
    }
  }, [tonWallet]);

  useEffect(() => {
    dispath(fetchTonWalletByBscWallet({ bscWallet: account }));
    dispath(fetchIsWhitelist({ bscWallet: account }));
  }, [account]);

  const err = useMemo(() => {
    if (!account) {
      return "Please connect wallet to continue";
    }
    if (account && !isWhitelist) {
      return "You need to connect the wallet address that was used to participate in the private sale of the CATO project.";
    }
    return null;
  }, [account, isWhitelist]);
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN --------------------------
  return (
    <PageFullWidth>
      <ContainerAirdrop>
        <ContainerFormSubmit>
          <Row gutter={[0, 24]} justify="center">
            <Col span={24}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <CsTitle>Receive SHAT Token</CsTitle>
                </Col>
                <Col span={24}>
                  <CsTextHeading>
                      Enter your TON wallet address to receive the corresponding SHAT tokens.
                  </CsTextHeading>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form
                name="submit-whitelist"
                autoComplete="off"
                form={form}
                size="large"
              >
                <Form.Item
                  name="accountTon"
                  rules={[
                    {
                      required: true,
                      message: "Please enter TON address",
                    },
                  ]}
                  validateTrigger={["onChange", "onBlur"]}
                >
                  <Input
                    placeholder="TON Address"
                    readOnly={Boolean(tonWallet)}
                    suffix={
                      <PasteIcon
                        style={{
                          cursor: Boolean(tonWallet)
                            ? "not-allowed"
                            : "pointer",
                        }}
                        onClick={handlePaste}
                      />
                    }
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col span={24}>
              <Flex vertical gap={8}>
                {Boolean(err) && <Text type="danger">{err}</Text>}
                <Button
                  onClick={handleSubmit}
                  disabled={
                    !account || !isWhitelist || isLoading || Boolean(tonWallet)
                  }
                  type="primary"
                  style={{ width: "100%" }}
                  size="large"
                >
                  {Boolean(tonWallet) ? "Submitted" : "Submit"}
                </Button>
              </Flex>
            </Col>
          </Row>
        </ContainerFormSubmit>
      </ContainerAirdrop>
      <ContainerMask />
    </PageFullWidth>
  );
};
export default ComingSoonScreen;
